import React from 'react'

function Hero() {
    return (


        <div className="container has-text-centered">
            <p className="title has-text-white">
                Welcome to Salter Diaz Solutions LLC
            </p>
            <p className="subtitle has-text-white">
                Specializing in Web/App development and consulting
            </p>
            
        </div>

    )
}

export default Hero