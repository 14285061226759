import React from 'react'
import { NavLink } from "react-router-dom";

function Navbar() {
    const [isActive, setisActive] = React.useState(false);

    return(
        <header className="navbar is-transparent" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                <a onClick={() => {
                        setisActive(!isActive);
                    }} role="button" className={`navbar-burger burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarmenutarget">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div id="navbarMenuHeroC" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                    <div className="navbar-start">
                    <NavLink
                            className="navbar-item"
                            to="/"
                            exact="true"
                        >
                            Home
                        </NavLink>
                        <NavLink
                            className="navbar-item"
                            to="/contactus"
                            exact="true"
                        >
                            Contact Us
                        </NavLink>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar