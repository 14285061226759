import React, { useRef, useState, useEffect } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import env from "react-dotenv";
import { Spinner } from "react-activity";
import '../styles/contactus.css'
import 'react-activity/dist/Spinner.css'


const Modal = ({ children, closeModal, modalState, title }) => {
    if (!modalState) {
        return null;
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={closeModal} />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{title}</p>
                    <button className="delete" onClick={closeModal} />
                </header>
                <section className="modal-card-body">
                    <div className="content">
                        {children}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={closeModal}>Close</button>

                </footer>
            </div>
        </div>
    );
}

function Contactus() {

    const captchaRef = useRef(null)

    const [formData, setFormData] = useState({ name: "", email: "", description: "" });
    const [formValid, setFormValid] = useState(true)
    const [fetching, setFetching] = useState(false)
    const [msgSent, setMsgSent] = useState(false)
    const [msgFailed, setMsgFailed] = useState(false)
    const [modalState, setModalState] = useState(false)


    const toggleModal = () => {
        setModalState((prev) => !prev)
    }

    const handleCancel = (event) => {
        event.preventDefault();
        window.location.reload();
        //captchaRef.current.reset();
        //setFormData({ name: '', email: '', description: '' })
        //setFormValid(true);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const recaptchaValue = captchaRef.current.getValue();
        //captchaRef.current.reset();

        if ((recaptchaValue === null) || (recaptchaValue === '')) {
            return;
        }

        setFetching((prev) => !prev);

        window.Email.send({
            SecureToken: "7bb8d183-a26e-4cde-8cca-d6d786980271",
            To: 'contactus@salterdiazsolutions.com',
            From: "website@salterdiazsolutions.com",
            Subject: "Contact Us Form Information",
            "reply-to": formData.email,
            Body: `<p>From: ${formData.name}</p><p>Email: ${formData.email}</p><p>Message: ${formData.description}</p>`
        }).then(
            message => {
                console.log(message);
                if (message !== "OK") {
                    setMsgSent(false);
                    setMsgFailed(true);
                    setFormValid(false);
                    setFormData({ name: '', email: '', description: '' })
                    toggleModal();
                    console.error(message);
                    
                }
                else {
                    setMsgSent(true);
                    setMsgFailed(false);
                    setFormValid(false);
                    setFormData({ name: '', email: '', description: '' })
                    toggleModal();
                }
                setFetching((prev) => !prev);
            }
        );
    }

    return (

        <div className="contactus">

            <div className="container">
                <h1 className='is-size-1'>Contact Us</h1>
                <p>Have a question?  Please fill out the following form and we will get back to you as soon as possible.</p>
                <br />
                <div className='control'>
                    <form onSubmit={handleSubmit}>

                        <div className="field">
                            <label className="label">Your Name</label>
                            <div className="control">
                                <input name='name' className="input" type="text" required value={formData.name} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Your Email Address</label>
                            <div className="control">
                                <input name="email" className="input" type="email" required value={formData.email} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Message</label>
                            <div className="control">
                                <textarea name="description" className="textarea" required onChange={handleChange} value={formData.description}></textarea>
                            </div>
                        </div>



                        {fetching ?
                            <div className="is-flex is-justify-content-center">
                                <Spinner color="#727981" size={32} speed={1} animating={fetching} />
                            </div>
                            :
                            <>
                                <hr />
                                <ReCAPTCHA ref={captchaRef} sitekey={env.RECAPTCHA_APP_SITE_KEY} />
                                <br />
                                <div className="field is-grouped">
                                    <div className="control">
                                        <button className="button is-link" disabled={!formValid}>Submit</button>
                                    </div>
                                    <div className="control">
                                        <button type='button' className="button is-link is-light" onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </>
                        }


                    </form>

                </div>
                <br />
                <p>Mailing Address:  5030 Cole Drive East  Mobile, AL  36619</p>
            </div>

            <Modal
                closeModal={toggleModal}
                modalState={modalState}
                title="Contact Form"
            >
                {msgSent && <p>The message was succesfully sent.</p>}
                {msgFailed && <p className="has-text-danger">The message was not sent.  Please try again later.</p>}
            </Modal>
        </div>
    )
}

export default Contactus