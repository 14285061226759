import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contactus from './Components/Contactus';
import Hero from './Components/Hero'
import Navbar from './Components/Navbar'
import './styles/app.css'

function App() {
  return (
    <div className="app">
      <section className="hero is-fullheight">
        <BrowserRouter>
          <div className="hero-head">
            <Navbar />
          </div>
          <div className="hero-body">

            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="/contactus" element={<Contactus />} />
            </Routes>

          </div>
          <div className="hero-footer">
            <div className="content has-text-centered">
              <p>Copyright 2023 Salter Diaz Solutions LLC</p>
            </div>
          </div>
        </BrowserRouter>
      </section>

    </div>
  );
}

export default App;
